import React from 'react';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Container,
  Grid,
  makeStyles,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Switch,
  Paper,
  FormControlLabel,
  CircularProgress,
  IconButton,
  Button,
  Typography
} from '@material-ui/core';
import decryptString from 'src/utils/decryptString';
import Loader from 'src/services/Loader';
import { customerDataService } from 'src/services/customerData'

import {
  Settings as SettingsIcon,
  Filter as FilterIcon,
  Briefcase as BriefcaseIcon
} from 'react-feather';


import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfGenerator from 'src/components/PdfGenerator';
import numberFormat from 'src/utils/numberFormat';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  formControl: {
    margin: theme.spacing(1),
    width: "95%",
    marginBottom: '15px'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  cardheader: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main
  },

  tableCell: {
    padding: '8px 10px',
    whiteSpace: 'nowrap'
  },

  searchBar: {
    maxWidth: '100%',
    marginBottom: '5px'
  },
  paper: {
    overflowX: 'auto',
  },

  icons: {
    fontSize: 14
  }
}));

const ProductDetails = ({ className, ...rest }) => {
  const [search, setSearchState] = React.useState('');
  const [filteredVal, setfilter] = React.useState({ manVal: '', catVal: '' });
  const [manList, setManufacturerList] = React.useState('');
  const [catList, setCategoryList] = React.useState('');
  const [finalData, setFinalData] = React.useState(null);
  const [showGroupedTable, setShowGroupedTable] = React.useState(false);

  const classes = useStyles();

  const [alertMessage, setalertMessage] = React.useState('');

  const [productData, setProductData] = React.useState('');
  const [dataCount, setdataCount] = React.useState(50);
  const [dataCountSetted, setdataCountStatus] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const [dataSwitch, setState] = React.useState({
    purchase: false,
    sales: false,
    stock: true,
    nillstock: false
  });
  const [isFilterOpened, setIsFilterOpened] = React.useState(false);
  const [isSettingsOpened, setIsSettingsOpened] = React.useState(false);
  const [customerData, setcustomerData] = React.useState('');

  const toggle = (s) => {
    if (s == 'filter') {
      setIsFilterOpened(wasOpened => !wasOpened)
      setIsSettingsOpened(false)
    }
    if (s == 'settings') {
      setIsSettingsOpened(wasOpened => !wasOpened)
      setIsFilterOpened(false)
    }
    if (s == 'groupedTable') {
      setfilter({ manVal: '', catVal: '' });
      setState({ ...dataSwitch, stock: true });
      setShowGroupedTable(showGroupedTable => !showGroupedTable)
      setIsFilterOpened(false)
      setIsSettingsOpened(false)
    }
  }

  const handleChange = (event) => {
    setFinalData(null)
    setState({ ...dataSwitch, [event.target.name]: event.target.checked });
  };

  React.useEffect(() => {
    customerDataService.getCustomerData().subscribe(customerData => {
      if (customerData) {
        setcustomerData(customerData)
        if (customerData.sc_platform == 4) { setState({ ...dataSwitch, procode: false }) }
      }
    })

    fetch(process.env.REACT_APP_API_URL + '/get_product_details.php', {
      method: 'POST',
      body: JSON.parse(localStorage.getItem('eToken'))
    })
      .then(response => response.json())
      .then(data => {

        if (data.status == "success") {
          setProductData(JSON.parse(decryptString(JSON.stringify(data.product_details))))
        }

        if (data.status == "failed") {
          setalertMessage(data.message)
        }
      }).catch(function (error) {
        console.log(error);
      })
  }, [productData])

  const searchSpace = (event, action) => {
    setfilter({ manVal: '', catVal: '' })

    if (action == "search") {
      setSearchState(event.currentTarget.value)
    } else {
      setSearchState('')
      setfilter({ [action]: event.target.value })
    }
    setFinalData(null)
  }

  const genPDF64 = (blob) => {
    var reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = function () {
      var base64data = reader.result;
      window.ReactNativeWebView.postMessage(base64data);
    }
  }
  const generatePDF = () => {
    setFinalData(filterOps(productData))
  }
  // Accepts the array and key
  const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {}); // empty object is the initial value for result object
  };

  if (!dataCountSetted && productData?.length) {
    setManufacturerList([...new Set(productData.map(item => item.manufacturer))])
    setCategoryList([...new Set(productData.map(item => item.category))])
    setdataCount(productData.length)
    setdataCountStatus(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filterOps = (data, search = null) => {
    return data.filter((data) => {
      if ((search == null || search == "") && ((filteredVal.manVal == '' || filteredVal.manVal == 'all_tyobe') || (filteredVal.catVal == '' || filteredVal.catVal == 'all_tyobe')))
        return data
      else {
        if ((search != null && search != "")) {
          if ((data.proname).toLowerCase().includes(search.toLowerCase()) || (data.procode).toString().toLowerCase().includes(search.toLowerCase())) {
            return data
          }
        } else {
          if ((filteredVal.manVal == '' || filteredVal.manVal == 'all_tyobe') && (filteredVal.catVal == '' || filteredVal.catVal == 'all_tyobe'))
            return data
          else if (filteredVal.manVal !== '' && filteredVal.manVal !== 'all_tyobe' && data.manufacturer == filteredVal.manVal)
            return data
          else if (data.category == filteredVal.catVal)
            return data
        }
      }
    })

  }

  const groupByOps = () => {
    var obj = [];

    Object.entries(groupBy(filterOps(productData), 'proname')).map(([key, value], index) => {
      obj[index] = { key: key, grosswt: (_.sumBy(value, item => Number(item.grosswt))).toFixed(3), stock: _.sumBy(value, item => Number(item.stock)) }
    })

    return obj
  }

  return (
    !(productData) ? <Loader datafromparent={{ errorMSG: alertMessage }} /> : <Container maxWidth={false} className={clsx(classes.root, className)}
      {...rest}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <Card
            className={clsx(className)}
            {...rest}
          >
            <CardHeader title="Stock Details" className={classes.cardheader} />
            <Divider />

            <Box textAlign="left" style={{ margin: '15px 0', padding: '0 10px' }}>
              {customerData?.sc_platform == 4 ? <FormControlLabel
                control={<Switch checked={showGroupedTable} onChange={()=> toggle('groupedTable')} name="productSummary" />}
                label="Show Product Wise Summary"
              /> : null}


              {!showGroupedTable ? <TextField
                fullWidth
                label="Enter Product Name / Product Code"
                name="product_search"
                onChange={(e) => searchSpace(e, 'search')}
                variant="filled"
                autoComplete="off"
                value={search}
                className={classes.searchBar}
              /> : null}
              {catList || manList ?
                <Grid container alignItems="center">
                  {manList ? <Grid item xs={5}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Manufacturer</InputLabel>
                      <Select
                        fullWidth
                        name="manufacturer_dd"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filteredVal.manVal || ''}
                        onChange={(e) => searchSpace(e, 'manVal')}
                        autoComplete="off"
                      >
                        <MenuItem value="all_tyobe">
                          <em>All</em>
                        </MenuItem>
                        {manList.map((data) => (
                          <MenuItem key={data} value={data}>
                            {data}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> : null}
                  <Grid item xs={1} align="center"><Typography variant="body1">Or</Typography></Grid>
                  {catList ? <Grid item xs={5}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Category</InputLabel>
                      <Select
                        fullWidth
                        name="category_dd"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filteredVal.catVal || ''}
                        onChange={(e) => searchSpace(e, 'catVal')}
                        autoComplete="off"
                      >
                        <MenuItem value="all_tyobe">
                          <em>All</em>
                        </MenuItem>
                        {catList.map((data) => (
                          <MenuItem key={data} value={data}>
                            {data}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> : null}

                  <Grid
                    item
                    xs={1}
                  >
                    {finalData ?
                      <PDFDownloadLink document={<PdfGenerator data={finalData} dataSwitch={dataSwitch} platform={customerData?.sc_platform} filteredVal={filteredVal} customerName={customerData.sc_customer_name} />} fileName="product_details.pdf" target="_blank">
                        {({ blob, url, loading, error }) => (url == null && !blob) ? <CircularProgress color="primary" />
                          : window.ReactNativeWebView ? genPDF64(blob) :
                            <IconButton color="primary" style={{ alignItems: 'center', textAlign: 'center' }}>
                              <GetAppIcon />
                            </IconButton>
                        }
                      </PDFDownloadLink> :
                      ((filteredVal.manVal !== '' && filteredVal.manVal !== 'all_tyobe') || (filteredVal.catVal !== '' && filteredVal.catVal !== 'all_tyobe')) ?
                        <IconButton onClick={() => generatePDF()} color="primary" style={{ alignItems: 'center', textAlign: 'center', padding: '0' }}>
                          <PictureAsPdfIcon />
                        </IconButton> : null}
                  </Grid>

                </Grid>
                : null}
              {!showGroupedTable ?
                <Grid>
                  <IconButton onClick={() => toggle('settings')} color={!isSettingsOpened ? "primary" : "secondary"} className={classes.icons}>
                    Column Settings &nbsp; <SettingsIcon />
                  </IconButton>

                  <IconButton onClick={() => toggle('filter')} color={!isFilterOpened ? "primary" : "secondary"} className={classes.icons}>
                    Filters &nbsp; <FilterIcon />
                  </IconButton>
                </Grid> : null}


              {isFilterOpened ? <>
                <FormControlLabel
                  control={<Switch checked={dataSwitch.nillstock} onChange={handleChange} name="nillstock" />}
                  label="Hide Nill Stock"
                />
              </> : isSettingsOpened ? <>
                <FormControlLabel
                  control={<Switch checked={dataSwitch.purchase} onChange={handleChange} name="purchase" />}
                  label="Purchase Rate"
                />
                <FormControlLabel
                  control={<Switch checked={dataSwitch.sales} onChange={handleChange} name="sales" />}
                  label="Sales Rate"
                />
                <FormControlLabel
                  control={<Switch checked={dataSwitch.stock} onChange={handleChange} name="stock" />}
                  label="Stock"
                />
              </> : null
              }

            </Box>
            <Divider />
            <Box textAlign="center" style={{ margin: '15px 0' }}>
              <Paper className={classes.paper}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow key={uuid()}>
                      <TableCell align="left" className={classes.tableCell} style={{ minWidth: "80px", display: !dataSwitch.procode ? 'none' : '' }}>Code</TableCell>
                      <TableCell align="left" className={classes.tableCell}>Product Name</TableCell>
                      {customerData?.sc_platform == 4 ? <>
                        {!showGroupedTable ? <TableCell align="left" className={classes.tableCell}>Tag ID</TableCell> : null}
                        <TableCell align="left" className={classes.tableCell}>Gross Wt</TableCell>
                        {!showGroupedTable ? <TableCell align="left" className={classes.tableCell}>HUID</TableCell> : null}
                      </>
                        : null}

                      <TableCell align="left" className={classes.tableCell} style={{ display: !dataSwitch.purchase ? 'none' : '', color: "green" }}>
                        Purchase Rate
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell} style={{ display: !dataSwitch.sales ? 'none' : '' }}>
                        Sales Rate
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell} style={{ display: !dataSwitch.stock ? 'none' : '' }}>
                        {customerData?.sc_platform == 4 ? "Qty" : "Stock"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {showGroupedTable ? <>
                      {groupByOps().map((value, index) => {
                        return (
                          <TableRow key={uuid()} style={{ background: index % 2 ? "#ddebf7" : "#fff" }}>
                            <TableCell align="left" className={classes.tableCell} style={{ minWidth: "180px", }}>
                              {value.key}
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>{value.grosswt}</TableCell>
                            <TableCell align="right" className={classes.tableCell}>
                              {value.stock}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                      <TableRow style={{ background: "#ededed" }}>
                        <TableCell align="right" className={classes.tableCell} style={{ minWidth: "180px", }}>
                          Total
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell}>{_.sumBy(groupByOps(), item => Number(item.grosswt))}</TableCell>
                        <TableCell align="left" className={classes.tableCell}>{_.sumBy(groupByOps(), item => Number(item.stock))}</TableCell>
                      </TableRow></>
                      : !showGroupedTable && filterOps(productData, search ? search : null).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => {
                        return (
                          <TableRow key={uuid()} style={{ display: (dataSwitch.nillstock && data.stock == 0) ? 'none' : '', background: index % 2 ? "#ddebf7" : "#fff" }}>
                            <TableCell align="left" className={classes.tableCell} style={{ minWidth: "80px", display: !dataSwitch.procode ? 'none' : '' }}>{data.procode}</TableCell>
                            <TableCell align="left" className={classes.tableCell} style={{ minWidth: "180px", }}>
                              {data.proname}
                            </TableCell>
                            {customerData?.sc_platform == 4 ?
                              <>
                                <TableCell align="left" className={classes.tableCell}>{data.batch}</TableCell>
                                <TableCell align="left" className={classes.tableCell}>{data.grosswt}</TableCell>
                                <TableCell align="left" className={classes.tableCell}>{data.huid}</TableCell></>
                              : null}
                            <TableCell align="left" className={classes.tableCell} style={{ minWidth: "105px", display: !dataSwitch.purchase ? 'none' : '', color: "green" }}>
                              {data.purchase ? numberFormat(data.purchase) : ''}
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell} style={{ minWidth: "80px", display: !dataSwitch.sales ? 'none' : '' }}>
                              {data.sales ? numberFormat(data.sales) : ''}
                            </TableCell>
                            <TableCell align="right" className={classes.tableCell} style={{ display: !dataSwitch.stock ? 'none' : '' }}>
                              {data.stock}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </Paper>
              {!showGroupedTable ? <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={dataCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              /> : null}
            </Box>
          </Card>
        </Grid>
      </Grid >
    </Container >
  );
};

ProductDetails.propTypes = {
  className: PropTypes.string
};

export default ProductDetails;
